// 顶部样式1
export const headerJsonOne = {
  // 二维码
  codeInfo: {
    left: 20,
    top: -25,
    width: 90,
    height: 90,
  },

  // 学号信息
  studentId: {
    // 考号位数
    number: 6,
    top: 56 - 15,
    left: 154 + 5,
    width: 28,
    height: 28,
  },
  // 条形码
  studentBarCode: {
    // 考号位数
    top: -25,
    left: 520 - 25,
    width: 215,
    height: 98,
  },
  title: {
    top: -25,
    left: 110 + 5,
    height: 32,
    width: 300,
    fontSize: 16,
    textAlign: "left",
    // 上下对齐方式1 顶部 2居中 3 底部
    verticalAlign: "1",
    fontWeight: "900",
  },
  studentMsg: {
    isShow: false,
  },
};
// 顶部样式2
export const headerJsonTwo = {
  // 二维码
  codeInfo: {
    left: 0,
    top: 44,
    width: 90,
    height: 90,
  },
  // 学号信息
  studentId: {
    // 考号位数
    number: 6,
    top: 103,
    left: 350,
    width: 28,
    height: 28,
  },
  // 条形码
  studentBarCode: {
    // 考号位数
    top: 44,
    left: 320,
    width: 250,
    height: 90,
  },
  title: {
    top: 0,
    left: 0,
    height: 32,
  },
  studentMsg: {},
};
// set默认参数
export const setting = {
  paperInfo: {},
  // 字号
  fontSize: "14",
  // 打印方式 1 单面 2 双面
  printType: "2",
  // 纸张类型1-A4 2-A3 3-8k
  paperType: "1",
  // 1标题在中间的类型 2标题在上方的类型
  headerStyle: "1",
  // 栏数
  columnNumber: 1,
  // 是否显示分值 0 无分数 1 有分数
  isScore: "1",
  // 批阅方式 无分数手写框1 对错框2
  noScoreScoreType: "1",
  // 是否显示暗线条
  hiddenGrid: "1",
  // 暗格线距离
  hiddenGridHeight: 36,
  // 字间距行高
  lineHeight: 1.5,
  // 批阅方式 有分数
  // 1 手写框 2 对错框 3划分框
  questionTypeItem: [
    // { type: "1", name: "选择题", scoreType: "1" },
    { type: "2", name: "填空题", scoreType: "1" },
    { type: "5", name: "解答题", scoreType: "1" },
  ],
  // 考号类型
  studentIdType: "3",
  // 是否显示题号 1 显示 0 不显示
  isShowQuestionNumber: "1",
  // 选择题前方是否需要添加花括号
  isAddprefix: "1",
  // 材料题模式 1 一道小题一个小框 2 多道小题 所有试题以3 3 3 2格式排列
  questionModel: "1",
  // 装订区域 1 需要 0 不需要
  gutter: 0,
  // 装订区域宽度
  gutterWidth: 30,
  // 默认 0 1题型排序
  questionTypeRanking: 0,
};

// A4的基本参数
export const A4Config = {
  width: 195,
  height: 272,
  paperWidth: 214,
  paperHeight: 297,
};
// A4的基本参数
export const A3_2Config = {
  width: 195,
  height: 261,
  paperWidth: 212,
  paperHeight: 297,
  pdfWidth: 424,
  pdfHeight: 270,
};
// 正确图片
export const imgSucc =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADqADAAQAAAABAAAADgAAAAC98Dn6AAAAbElEQVQoFWNgGDKAmUSXugPV/wHij6To8wQqvgzEwqRosgUqvgHEsqRoMgIqvgXEarg0LQBKaKBJgvggTfpo4ihca6giV6ioPJAGOc8KysdLKQJlLwBxBRBfAWI3ICYa8AFVLgfiIKJ1DG6FAO9PDdnKE2VNAAAAAElFTkSuQmCC";
// 错误图片
export const imgErr =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADqADAAQAAAABAAAADgAAAAC98Dn6AAAAW0lEQVQoFWNgGLJgCtDlhlhcDxIDyeEEIAW3gNgASQWIfRuIsRmIpAyiCaaZaE0wE0AabgIxyACsNjHBVGKhGYFiIEw0QHYeyDai/IdNITYxDFeQHR0YJg0eAQCZxQ8NWYXNwAAAAABJRU5ErkJggg==";
// 半对图片
export const imgSectional =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADqADAAQAAAABAAAADgAAAAC98Dn6AAAAh0lEQVQoFWNgGDKAmUSXugPV/wHij6To8wQqvgzEwoQ0qQEVFEIV2QLpG0AsC+XjpdiBsquBeA0Q3wJikEFYwQKgqAaajCaQ/x6I9wIxB5ocnGsNZIFMdoWKyANpkPOsgLgaiGcCMU6gCJS5AMQVQHwFiN2AGAZYYQxcNB9QYjkQB+FSMMTEATRlEWEwx4OKAAAAAElFTkSuQmCC";
