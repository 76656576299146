<template>
  <div class="sheetHeader">
    <div class="sheetHeader-box">
      <img src="@/assets/newLogo.png" alt="" />
      <div>
        <template v-if="$route.query.password == 1">
          <el-button :loading="imgLoading" type="primary" @click="showSheet()"
            >标注卡信息</el-button
          >
          <el-button @click="$parent.getNewFn()">重新加载</el-button>
        </template>

        <el-button :loading="pdfLoading" @click="getPdfUrl()">预览</el-button>

        <el-button type="primary" :loading="btnLoading" @click="saveFn()">
          保存
        </el-button>
      </div>
    </div>
    <subFrom ref="subFrom"></subFrom>
  </div>
</template>
<script>
import { saveContent, getPdf, testMark } from "@/core/api/homework/sheet";
import { getStore, setStore } from "@/core/util/store";
import cssStr from "../cssZip.js";
import subFrom from "./subFrom.vue";
import { getInfo } from "@/core/api/homework/sheet";

export default {
  name: "SheetHeader",
  components: {
    subFrom,
  },
  data() {
    return {
      btnLoading: false,
      imgLoading: false,
      pdfLoading: false,
      cssStr: cssStr,
      // subForm:{}
    };
  },
  created() {},
  methods: {
    showSheet() {
      this.imgLoading = true;
      testMark({ uuid: this.$route.query.uuid })
        .then((res) => {
          setStore({
            name: "showImgItem",
            type: 1,
            content: res.data.data,
          });
          const { href } = this.$router.resolve({
            path: "/showImgItem",
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
          this.imgLoading = false;
        })
        .catch(() => {
          this.imgLoading = false;
        });
    },
    getPdfUrl() {
      this.pdfLoading = true;
      getPdf({ uuid: this.$route.query.uuid })
        .then((res) => {
          window.open(res.data.data);
          this.pdfLoading = false;
        })
        .catch(() => {
          this.pdfLoading = false;
        });
    },
    async saveFn() {
      await getInfo({
        uuid: this.$route.query.uuid,
      })
        .then((res) => {
          let settingObj = getStore({
            name: "settingObj",
            type: 1,
          });
          let data = {
            name: settingObj.name,
            levelId: res.data.data.levelId,
            subjectId: res.data.data.subjectId,
            resGradeId: res.data.data.resGradeId || "",
            bookId: res.data.data.bookId || "",
            chapterId: res.data.data.chapterId || "",
            chapterIds: res.data.data.chapterIds || "",
            bookIds: res.data.data.bookIds || "",
            isShare: res.data.data.isShare || 0,
          };

          this.$refs.subFrom.init(data);
        })
        .catch(() => {});
    },
    getFooter(item, index) {
      const footer = document.getElementById("footer" + (index + 1));
      const { clientWidth, clientHeight } = footer;
      const spanRect = footer.getBoundingClientRect();
      const parent = document.getElementById("footerF" + (index + 1));
      const parentRect = parent.getBoundingClientRect();
      const left = spanRect.left - parentRect.left;
      return {
        left: left,
        top: item.footer.top,
        width: clientWidth,
        height: clientHeight,
      };
    },
    getDom(paperType) {
      let fDom = document.createElement("div");

      fDom.style.width = "428mm";
      fDom.style.height = "297mm";
      if (paperType == 2) {
        fDom.setAttribute("class", "A3");
      } else {
        fDom.setAttribute("class", "k8");
      }

      return fDom;
    },
    async subData(data, that) {
      let Base64 = require("js-base64").Base64;
      //先将name重新赋值  再获取复合体中的子题信息
      let settingObj = getStore({
        name: "settingObj",
        type: 1,
      });
      let questionList = getStore({
        name: "questionList",
        type: 1,
      });
      // let questionArr = questionList.map((item) => item.questionList);
      // questionArr = questionArr.flat();
      let oldName = settingObj.name;
      settingObj.name = data.name;

      let pdfItem = getStore({
        name: "pdfItem",
        type: 1,
      });
      let setting = getStore({
        name: "setting",
        type: 1,
      });
      let copyItem = JSON.parse(JSON.stringify(pdfItem));

      // 替换节点中的name
      let html = document.getElementById("paperBox").innerHTML;
      if (setting.paperType == 3 || setting.paperType == 2) {
        let dom = document.getElementById("paperBox");
        const div = document.createElement("div");
        let fDom = this.getDom(setting.paperType);
        let str = "";
        let key = 0;
        dom.childNodes.forEach((item, index) => {
          key = key + 1;
          str = str + item.outerHTML;
          // console.log(index);
          if (key % 2 == 0) {
            fDom.innerHTML = str;
            div.appendChild(fDom);
            str = "";
            fDom = this.getDom(setting.paperType);
          } else {
            if (index == dom.childNodes.length - 1) {
              fDom.innerHTML = str;
              div.appendChild(fDom);
            }
          }
        });
        // console.log(div);
        // document.body.appendChild(div);
        html = div.innerHTML;
        // console.log(dom);
      }
      // console.log(html);
      // if (html) return;
      //  替换反斜杠  修改后的名字 还有/n /r
      html = JSON.stringify(html).replace(/\\n/g, "");
      html = html.replace(/\\r/g, "");
      html = html.replace(/\\/g, "");
      html = html.replaceAll(`${oldName} <img`, `${data.name} <img`);

      html = Base64.encode(encodeURIComponent(html));
      // 这里获取一下 材料题 对每个页面存在的材料题进行赋值或者添加
      pdfItem = pdfItem.map((item, index) => {
        // 计算下footer居中后的位置
        copyItem[index].footer = this.getFooter(item, index);
        // 重新赋值名字
        copyItem[index].name = data.name;

        item.questionList = item.questionList.map((aItem, akey) => {
          if (aItem.groupType == 1) return aItem;
          aItem.questionItem = aItem.questionItem.map((bItem) => {
            if (
              bItem.lastMaterialId &&
              bItem.questionLocation &&
              bItem.questionLocation.length > 0
            ) {
              // console.log(bItem);
              // 计算出材料的宽度  高度  需要所有数据相加
              let top = bItem.questionLocation[0].top;
              let height = 0;
              bItem.questionLocation.map((oi) => {
                height = height + Number(oi.contentHeight);
              });
              // 如果存在最后一道材料题 处理数据
              // 获取出所有的材料小题
              let questionLocationArr = bItem.questionLocation.map(
                (item) => item.questionList
              );
              questionLocationArr = questionLocationArr.flat();
              // 获取当前试题所有的id  好拿来做对比
              let ids = aItem.questionItem.map((item) => item.questionId);
              let concatArr = [];
              //循环材料题中绑定的所有小题
              questionLocationArr.map((citem) => {
                if (ids.indexOf(citem.questionId) != -1) {
                  let fIndex = ids.indexOf(citem.questionId);
                  //答案区域
                  copyItem[index].questionList[akey].questionItem[
                    fIndex
                  ].scoreLocation = citem.scoreLocation;
                  copyItem[index].questionList[akey].questionItem[
                    fIndex
                  ].questionScore = citem.questionScore;
                  // 试题作答区域
                  copyItem[index].questionList[akey].questionItem[
                    fIndex
                  ].answerLocation = {
                    left: 0,
                    top: top,
                    height: height,
                    width: bItem.questionWidth,
                  };
                  if (
                    !copyItem[index].questionList[akey].questionItem[fIndex]
                      .scoreLocation
                  ) {
                    delete copyItem[index].questionList[akey].questionItem[
                      fIndex
                    ].scoreLocation;
                  }
                } else {
                  let data = {
                    scoreLocation: citem.scoreLocation,
                    answerLocation: {
                      left: 0,
                      top: top,
                      height: height,
                      width: bItem.questionWidth,
                    },

                    questionId: citem.questionId,
                    questionNum: citem.questionNum,
                    questionFNum: citem.questionFNum,
                    questionKey: citem.questionKey,
                    questionScore: citem.questionScore,
                    isHidden: true,
                  };

                  if (!data.scoreLocation) {
                    delete data.scoreLocation;
                  }
                  concatArr.push(data);
                }
              });

              copyItem[index].questionList[akey].questionItem = JSON.parse(
                JSON.stringify([
                  ...copyItem[index].questionList[akey].questionItem,
                  ...concatArr,
                ])
              );
            }
            return bItem;
          });

          return aItem;
        });
        return item;
      });

      copyItem = JSON.parse(JSON.stringify(copyItem));
      let addArr = [1, 2, 3, 4];
      copyItem = copyItem.map((item) => {
        item.questionList = item.questionList.map((bItem) => {
          if (bItem.groupType == 1) return bItem;
          bItem.questionItem = bItem.questionItem.map((cItem) => {
            if (addArr.indexOf(cItem.hwQuestionType) != -1) {
              let height = 0;
              cItem.questionContentList.map((dItem) => {
                height += dItem.questionDefaultHeight;
              });
              cItem.answerLocation = {
                height: height,
                left: 0,
                top: cItem.top,
                width: cItem.questionWidth,
              };
            }
            return cItem;
          });
          return bItem;
        });
        return item;
      });
      let extInfo = {
        questionList: questionList,
        pdfItem: copyItem,
        setting: setting,
        settingObj: settingObj,
      };
      // console.log(JSON.parse(JSON.stringify(copyItem)));
      // if (copyItem) {
      //   console.log(JSON.parse(JSON.stringify(copyItem)));
      //   return;
      // }
      let css = Base64.encode(encodeURIComponent(JSON.stringify(this.cssStr)));
      //替换二维码测试
      extInfo = Base64.encode(JSON.stringify(extInfo));
      let dataObj = {
        css: css,
        html: html,
        extInfo: extInfo,
        uuid: this.$route.query.uuid,
        dpi: 140,
        pageWidth: 214,
        pageHeight: 297,
      };
      if (setting.paperType != 1) {
        dataObj.pageWidth = 428;
        dataObj.pageHeight = 297;
      }

      Object.assign(dataObj, data);
      this.addSaveContent(dataObj, settingObj, that);
    },
    delay() {
      return new Promise((resolve) => setTimeout(resolve));
    },
    addSaveContent(data, settingObj, that) {
      that.btnLoading = true;
      saveContent(data)
        .then(() => {
          setStore({
            name: "settingObj",
            content: settingObj,
            type: 1,
          });
          that.btnLoading = false;
          that.dialogVisible = false;
          if (this.$route.query.password) {
            this.$message({
              showClose: true,
              message: "保存成功，调试模式，不跳转!",
              type: "success",
            });
          } else {
            this.$message({
              showClose: true,
              message: "保存成功!",
              type: "success",
            });
            this.$router.push({
              path: "/homework/myHomework",
            });
          }
        })
        .catch(() => {
          that.btnLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.sheetHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 58px;
  z-index: 99;
  padding: 14px 80px;

  border-bottom: 1px solid #dddddd;
  background: #10131b;
  .sheetHeader-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  img {
    height: 30px;
  }
}
</style>
